import { motion } from "framer-motion"
import { graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import PageFooter from "../components/PageFooter"
import Img from "gatsby-image"

import { carouselCaption } from "./../styles/accessDetail.module.scss"

function facilityDetails({ data }) {
  const title = data.accessDetailsJson.product
  const headerImg = data.accessDetailsJson.headerImg.childImageSharp.fluid
  const thumb = data.accessDetailsJson.img.childImageSharp.fluid
  const detail = data.accessDetailsJson.detailImg
  const label = data.accessDetailsJson.labelImg
  const desc = data.accessDetailsJson.desc

  console.log(detail)

  return (
    <Layout>
      <Helmet>
        <title>
          {title} | Grand Wisata Bekasi | Kota Mandiri Terintegrasi dan
          Terencana
        </title>
        <meta
          name="description"
          content="Grand Wisata mengerti bahwa di era modernisasi seperti saat ini, mobilitas adalah hal penting. Untuk itu, Grand Wisata hadir dilokasi strategis dan dengan akses yang baik untuk memudahkan mobilitas anda."
        />
        <meta
          name="keywords"
          content="grand wisata bekasi,grand wisata,sinar mas land,properti,real estate,rumah"
        />
        <meta name="distribution" content="web" />
        <meta
          property="og:title"
          content={`${title} | Grand Wisata Bekasi | Kota Mandiri Terintegrasi dan
          Terencana`}
        />
        <meta
          property="og:description"
          content="Grand Wisata mengerti bahwa di era modernisasi seperti saat ini, mobilitas adalah hal penting. Untuk itu, Grand Wisata hadir dilokasi strategis dan dengan akses yang baik untuk memudahkan mobilitas anda."
        />
        <meta property="og:image" content={thumb} />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <PageHeader bg={headerImg}>{title}</PageHeader>
        <section className="container">
          {desc.length > 1 ? <p>{desc}</p> : ""}
          <div
            id="carouselExampleIndicators"
            class="carousel carousel-dark mt-5"
            data-bs-ride="false"
          >
            <div class="carousel-indicators">
              {detail.map((img, idx) => {
                return (
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={idx}
                    class={idx === 0 ? "active" : ""}
                    aria-current="true"
                    aria-label={`Slide ${idx}`}
                    key={idx}
                  ></button>
                )
              })}
            </div>
            <div class="carousel-inner">
              {detail.map((img, idx) => {
                return (
                  <div
                    className={
                      idx === 0 ? "carousel-item active" : "carousel-item"
                    }
                    key={idx}
                  >
                    <Img
                      fluid={img.childImageSharp.fluid}
                      className={` d-block w-100`}
                      alt="..."
                    />
                    <div
                      class={`${carouselCaption} carousel-caption text-center d-block`}
                    >
                      <h4>{label[idx]}</h4>
                    </div>
                  </div>
                )
              })}
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </section>
        <PageFooter></PageFooter>
      </motion.div>
    </Layout>
  )
}

export const query = graphql`
  query accessDetails($slug: String) {
    accessDetailsJson(link: { eq: $slug }) {
      detailImg {
        childImageSharp {
          fluid(maxWidth: 6000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      labelImg
      desc
      headerImg {
        childImageSharp {
          fluid(maxWidth: 6000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img {
        childImageSharp {
          fluid(maxWidth: 6000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      link
      product
    }
  }
`

export default facilityDetails
