import React from "react"
import Img from "gatsby-image"
import "./pageheader.scss"

function PageHeader({ bg, children }) {
  return (
    <header className="page-header">
      <Img className="background-img" fluid={bg} alt="Banner" />
      <div className="bg-layer"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>{children}</h2>
          </div>
        </div>
      </div>
    </header>
  )
}

export default PageHeader
